import './index.css';

import React from 'react';

import ReactDOM from 'react-dom/client';

import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';

import Quiz from './components/Quiz';
import { QuizProvider } from './contexts/quiz';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})
root.render(
  <QueryClientProvider client={queryClient}>
    <QuizProvider>
      <Quiz />
    </QuizProvider>
  </QueryClientProvider>
);

