const Answer = ({ answerText, onSelectAnswer, currentAnswer, correctAnswer }) => {
  const isCorrentAnswer = currentAnswer && answerText === correctAnswer;
  const isWrongAnswer = currentAnswer === answerText && currentAnswer !== correctAnswer;
  const correctAnswerStyle = isCorrentAnswer ? 'test-modal-btn--checked' : '';
  const wrongAnswerStyle = isWrongAnswer ? 'test-modal-btn--wrong' : '';
  const disabledStyle = currentAnswer ? 'test-modal-btn--disabled' : '';

  return (
    <li className={`test-modal-btn ${correctAnswerStyle} ${wrongAnswerStyle} ${disabledStyle}`} onClick={() => onSelectAnswer(answerText)}>
      <div className="test-modal-btn__check">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
          <path d="M8 12.5L11 15.5L16.5 10" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </div>
      <p className="test-modal-btn__text">{answerText}</p>
    </li>
  );
}

export default Answer;
