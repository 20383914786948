import { useContext } from 'react';

import { QuizContext } from '../contexts/quiz';
import Answer from './Answer';

const Question = () => {
  const [quizState, dispatch] = useContext(QuizContext);
  const currentQuestion = quizState.questions[quizState.currentQuestionIndex];
  const currentAnswerResultStyle = quizState.isCurrentAnswerCorrect ? 'test-modal__result--check' : 'test-modal__result--wrong';
  const applyCurrentAnswerResultStyle = quizState.currentAnswer ? currentAnswerResultStyle : '';
  return (
    <>
      <div className="test-modal__content">
        <p className="test-modal__title">{currentQuestion ? currentQuestion.question : ''}</p>
        <ul className="test-modal__btns">
          {quizState.answers.map((answer, index: number) => (
            <Answer key={index}
              answerText={answer}
              correctAnswer={quizState.correctAnswer}
              currentAnswer={quizState.currentAnswer}
              onSelectAnswer={(answertext) => dispatch({ type: 'SELECT_ANSWER', payload: answertext })} />
          ))}
        </ul>
      </div>
      <div className={`test-modal__result ${applyCurrentAnswerResultStyle}`}>

        {!quizState.currentAnswer
          ? (
            currentQuestion &&
              <picture className="test-modal__img">
                {currentQuestion.desktopImage &&
                  <img src={`${currentQuestion.desktopImage}`} alt="result" />
                }
                {currentQuestion.mobileImage && 
                  <source srcSet={`${currentQuestion.mobileImage}`} media="(max-width: 767px)" />
                }
              </picture>
          ) : (
            <div className="test-modal__result-text">
              {quizState.isCurrentAnswerCorrect
                ? (
                  <>
                    <svg xmlns="http://www.w3.org/2000/svg" width="65" height="54" viewBox="0 0 65 54" fill="none">
                      <path d="M60.3905 0H4.61542C2.06837 0 0 2.06246 0 4.59769V38.4776C0 41.0128 2.06837 43.0753 4.61542 43.0753H11.4292C12.5934 46.1837 11.7543 49.6822 9.24266 51.9279C8.63988 52.4716 9.024 53.4762 9.83362 53.4762H16.0978C22.0666 53.4762 26.3097 48.4471 26.026 43.0753H60.3846C62.9316 43.0753 65 41.0128 65 38.4776V4.59769C65 2.06246 62.9316 0 60.3846 0H60.3905ZM63.233 38.4776C63.233 40.0377 61.9565 41.3024 60.3905 41.3024H25.0509C24.5072 41.3024 24.0876 41.7929 24.1763 42.3307C24.9268 47.0111 21.3515 51.7033 16.1096 51.7033H11.837C13.9054 48.8726 14.3368 45.12 12.8357 41.8224C12.6939 41.5033 12.3748 41.3024 12.0261 41.3024H4.62133C3.05528 41.3024 1.7788 40.0318 1.7788 38.4776V4.59769C1.7788 3.03755 3.05528 1.77289 4.62133 1.77289H60.3905C61.9565 1.77289 63.233 3.04346 63.233 4.59769V38.4776Z" fill="#6ABD17" />
                      <path d="M22 21.913L28.3636 28L43 14" stroke="#6ABD17" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <p>{quizState.currentResult}</p>
                  </>
                ) : (
                  <>
                    <svg xmlns="http://www.w3.org/2000/svg" width="65" height="54" viewBox="0 0 65 54" fill="none">
                      <path d="M60.3905 0H4.61542C2.06837 0 0 2.06246 0 4.59769V38.4776C0 41.0128 2.06837 43.0753 4.61542 43.0753H11.4292C12.5934 46.1837 11.7543 49.6822 9.24266 51.9279C8.63988 52.4716 9.024 53.4762 9.83362 53.4762H16.0978C22.0666 53.4762 26.3097 48.4471 26.026 43.0753H60.3846C62.9316 43.0753 65 41.0128 65 38.4776V4.59769C65 2.06246 62.9316 0 60.3846 0H60.3905ZM63.233 38.4776C63.233 40.0377 61.9565 41.3024 60.3905 41.3024H25.0509C24.5072 41.3024 24.0876 41.7929 24.1763 42.3307C24.9268 47.0111 21.3515 51.7033 16.1096 51.7033H11.837C13.9054 48.8726 14.3368 45.12 12.8357 41.8224C12.6939 41.5033 12.3748 41.3024 12.0261 41.3024H4.62133C3.05528 41.3024 1.7788 40.0318 1.7788 38.4776V4.59769C1.7788 3.03755 3.05528 1.77289 4.62133 1.77289H60.3905C61.9565 1.77289 63.233 3.04346 63.233 4.59769V38.4776Z" fill="#EC1D1D" />
                      <path d="M25.5 14L40 28.5" stroke="#EC1D1D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M40 14L25.5 28.5" stroke="#EC1D1D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <p>{quizState.currentResult}</p>
                  </>
                )}
            </div>
          )}

      </div>
    </>
  );
}

export default Question;
