import { useContext } from 'react';

import { QuizContext } from '../contexts/quiz';

const Navigation = () => {
  const [quizState, dispatch] = useContext(QuizContext);
  const nextButtonStyle = quizState.currentAnswer ? '' : 'test-modal__btn--disabled';
  const prevButtonStyle = quizState.currentQuestionIndex > 0 ? '' : 'test-modal__btn--hide';
  return (
    <div className="test-modal__footer">
      <div className={`test-modal__btn test-modal__btn--prev ${prevButtonStyle}`} onClick={() => dispatch({ type: "PREV_QUESTION" })}>
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
          <path d="M13 5H1M1 5L5.66667 0.5M1 5L5.66667 9.5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        <span>Назад</span>
      </div>
      <ul className="test-modal__pagination">
        {quizState.questions.map((questio, index: number) => (
          <li key={index} className={index === quizState.currentQuestionIndex ? "active" : ""}></li>
        ))}
      </ul>
      <div className={`test-modal__btn test-modal__btn--next ${nextButtonStyle}`} onClick={() => dispatch({ type: "NEXT_QUESTION" })}>
        <span>Далее</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
          <path d="M1 5H13M13 5L8.33333 0.5M13 5L8.33333 9.5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </div>
    </div>
  );
}

export default Navigation;

