import { useContext } from 'react';

import { QuizContext } from '../contexts/quiz';

const Result = () => {
	const [quizState, dispatch] = useContext(QuizContext);

	let resultVariant = {
		title: 'Тест пройден!',
		text: 'Так держать)',
		desktopImage: '',
		mobileImage: ''
	}

	function between(x, min, max) {
		return x >= min && x <= max;
	}

	quizState.results.forEach(function(item) {
		if(item.validAnswers) {
			if(item.validAnswers[1]) {
				if (between(quizState.correctAnswersCount, item.validAnswers[0], item.validAnswers[1])) {
					resultVariant = item
				}
			} else if(quizState.correctAnswersCount === item.validAnswers[0]) {
				resultVariant = item
			}
		}
	})

	return (
		<div className="test-modal-rating">
			<div className="test-modal__wrap">
				<div className="test-modal-rating__title">Результаты теста</div>
				<div className="test-modal__holder test-modal-rating__holder">
					<button data-hystclose className="hystmodal__close">
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
							<path d="M15 1L1 15" stroke="#30375B" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M15 15L1 0.999999" stroke="#30375B" strokeLinecap="round" strokeLinejoin="round" />
						</svg>
					</button>
					<div className="test-modal-rating__content">
						<div className="test-modal-rating__ico">
							<svg xmlns="http://www.w3.org/2000/svg" width="56" height="55" viewBox="0 0 56 55" fill="none">
								<path d="M18.8335 27.5L25.4335 34.1L37.5335 22" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
							</svg>
						</div>
						<div className="test-modal-rating__subtitle">{resultVariant.title}</div>
						<div className="test-modal-rating__text">{resultVariant.text}</div>
					</div>
					<div className="test-modal-rating__img">
            {resultVariant && 
              <picture className="test-modal__img">
                {resultVariant.mobileImage && 
                  <source srcSet={`${resultVariant.mobileImage}`} media="(max-width: 767px)" />
                }
                {resultVariant.desktopImage && 
                  <img src={`${resultVariant.desktopImage}`} alt="result" />
                }
              </picture>
            }
					</div>
				</div>
			</div>
			<div className="test-modal__footer">
				<div className="test-modal-rating__btns">
					<div className={`test-modal__btn test-modal__btn--next`} onClick={() => dispatch({ type: "RESTART" })}>
						<span>Начать сначала</span>
					</div>
					<div data-hystclose className="test-modal-rating__close">
						<span>Закрыть</span>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Result;