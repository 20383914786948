import {
  createContext,
  useReducer,
} from 'react';

const initialState = {
  questions: [],
  results: [],
  currentQuestionIndex: 0,
  showResults: false,
  answers: [],
  currentAnswer: '',
  correctAnswersCount: 0,
  currentResult: '',
  doneAnswer: [],
  isCurrentAnswerCorrect: false,
  correctAnswer: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SELECT_ANSWER':
      let correctAnswersCount = 0;
      let currentResult = '';
      let isCurrentAnswerCorrect = false;
      let correctAnswer = '';
      let currentAnswers = [];

      state.questions[state.currentQuestionIndex].answers.forEach(
        (item, index) => {
          if (action.payload === item.answer) {
            if (item.isCorrect) {
              correctAnswersCount = state.correctAnswersCount + 1;
              isCurrentAnswerCorrect = true;
              correctAnswer = item.answer;
            } else {
              correctAnswersCount = state.correctAnswersCount;
              isCurrentAnswerCorrect = false;
              correctAnswer = '';
            }
            currentResult = item.result;
          }

          currentAnswers[index] = item.answer;
        }
      );

      return {
        ...state,
        currentAnswer: action.payload,
        correctAnswersCount,
        currentResult,
        isCurrentAnswerCorrect,
        correctAnswer,
        answers: currentAnswers,
        doneAnswer: [...state.doneAnswer, action.payload],
      };
    case 'NEXT_QUESTION':
      const isNewQuestion =
        state.doneAnswer.length < state.currentQuestionIndex;

      if (state.currentAnswer === '') {
        return {
          ...state,
        };
      } else {
        let currentAnswers = [];
        let currentResult = '';
        let isCurrentAnswerCorrect = false;
        let correctAnswer = '';

        if (state.currentQuestionIndex !== state.questions.length - 1) {
          state.questions[state.currentQuestionIndex + 1].answers.forEach(
            (item, index) => {
              currentAnswers[index] = item.answer;

              if (
                item.answer === state.doneAnswer[state.currentQuestionIndex + 1]
              ) {
                isCurrentAnswerCorrect = item.isCorrect;
                currentResult = item.result;

                if (item.isCorrect) {
                  correctAnswer = item.answer;
                }
              }
            }
          );
        }

        return {
          ...state,

          currentQuestionIndex:
            state.currentQuestionIndex === state.questions.length - 1
              ? state.currentQuestionIndex
              : state.currentQuestionIndex + 1,

          showResults:
            state.currentQuestionIndex === state.questions.length - 1,

          answers:
            state.currentQuestionIndex === state.questions.length - 1
              ? []
              : currentAnswers,

          currentAnswer: isNewQuestion
            ? ''
            : !(state.currentQuestionIndex === state.questions.length - 1)
            ? state.doneAnswer[state.currentQuestionIndex + 1]
            : '',

          currentResult,
          correctAnswer,
          isCurrentAnswerCorrect,
        };
      }
    case 'PREV_QUESTION':
      if (state.currentQuestionIndex !== 0) {
        let isCurrentAnswerCorrect = false;
        let currentAnswers = [];
        let currentResult = '';
        let correctAnswer = '';

        if (state.currentQuestionIndex !== 0) {
          state.questions[state.currentQuestionIndex - 1].answers.forEach(
            (item, index) => {
              currentAnswers[index] = item.answer;

              if (
                item.answer === state.doneAnswer[state.currentQuestionIndex - 1]
              ) {
                isCurrentAnswerCorrect = item.isCorrect;
                currentResult = item.result;

                if (item.isCorrect) {
                  correctAnswer = item.answer;
                }
              }
            }
          );
        }

        return {
          ...state,
          currentQuestionIndex:
            state.currentQuestionIndex === 0
              ? state.currentQuestionIndex
              : state.currentQuestionIndex - 1,

          showResults: state.currentQuestionIndex === state.questions.length,

          currentAnswer:
            state.currentQuestionIndex === 0
              ? state.doneAnswer[state.currentQuestionIndex]
              : state.doneAnswer[state.currentQuestionIndex - 1],

          answers: currentAnswers,

          isCurrentAnswerCorrect,
          correctAnswer,
          currentResult,
        };
      } else {
        return {
          ...state,
        };
      }

    case 'RESTART':
      let startAnswers = [];

      state.questions[0].answers.forEach((answer, index) => {
        startAnswers[index] = answer.answer;
      });

      return {
        ...state,
        currentQuestionIndex: 0,
        showResults: false,
        answers: startAnswers,
        currentAnswer: "",
        correctAnswersCount: 0,
        isCurrentAnswerCorrect: false,
        doneAnswer: [],
      };
    case 'LOAD':
      let answers = [];

      action.payload.questions[0].answers.forEach((answer, index) => {
        answers[index] = answer.answer;
      });

      return {
        ...state,
        questions: action.payload.questions,
        results: action.payload.results,
        answers,
      };
    default:
      return state;
  }
};

export const QuizContext = createContext();

export const QuizProvider = ({ children }) => {
  const value = useReducer(reducer, initialState);
  return <QuizContext.Provider value={value}>{children}</QuizContext.Provider>;
};
