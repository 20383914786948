import React, {
  useContext,
  useEffect,
} from 'react';

import axios from 'axios';

import { useQuery } from '@tanstack/react-query';

import { QuizContext } from '../contexts/quiz';
import Navigation from './Navigation';
import Question from './Question';
import Result from './Result';

const API = process.env.REACT_APP_API || '/api/quiz';

async function fetchData() {
  const { data } = await axios.get(API);
  return data;
}

const Quiz = () => {
  const [quizState, dispatch] = useContext(QuizContext);

  const { data, isSuccess } = useQuery({
    queryKey: ['question'],
    queryFn: () => fetchData()
  })

  useEffect(() => {
    if (isSuccess) {
      dispatch({ type: 'LOAD', payload: data });
    }
  }, [isSuccess]);

  return (
    <div className="quiz">
      {!isSuccess ? (
        <>
          <div className="test-modal__loading">Загрузка...</div>
        </>
      ) : (
        !quizState.showResults ? (
          <>
            <div className="test-modal__wrap">
              <div className="test-modal__holder">
                <button data-hystclose className="hystmodal__close">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M15 1L1 15" stroke="#30375B" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M15 15L1 0.999999" stroke="#30375B" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </button>
                <Question />
              </div>
            </div>
            <Navigation />
          </>
        ) : (
          <Result />
        )
      )}
    </div>
  );
}

export default Quiz;


